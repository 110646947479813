<template>
  <!-- 沙龙课节管理 -->
  <div class="lesson_manage_home">
    <div class="manage-wrapper" style="flex:1">
      <div class="table-wrapper">
        <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
          <div class="formInline-left">
          </div>
          <div class="formInline-right" @click="goCreate()">
            <i class="el-icon-circle-plus-outline"></i>
            <span class="csp">创建课节</span>
          </div>
        </el-form>

        <div class="table">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" style="background:#2373EC" align="center"></el-table-column>

            <el-table-column prop="courseProjectId" label="沙龙编号" align="center"></el-table-column>

            <el-table-column prop="id" label="课节编号" align="center"></el-table-column>

            <el-table-column prop="lessonsName" label="课节名称" width="200px" align="center"></el-table-column>

            <el-table-column width="240px" prop="lessonsBeginDate" label="上课时间" align="center">
              <template slot-scope="scope">
                <p v-if="course.pattern == 0">
                  {{`${dayjs(scope.row.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss')}-${dayjs(scope.row.lessonsEndTime*1000).format('HH:mm:ss')}`}}
                </p>
                <p v-if="course.pattern == 1 || course.type == 3">
                  {{dayjs(scope.row.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss')}}
                </p>
              </template>
            </el-table-column>

            <el-table-column prop="teacherName" label="授课老师" align="center"></el-table-column>
            <el-table-column prop="assistantName" label="助教" align="center"></el-table-column>
            <el-table-column prop="type" label="操作" fixed="right" width="150px" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="goPage(scope.row, 'detail')">查看</el-button>
                <el-button type="text" size="small" @click="goPage(scope.row, 'edit')">编辑</el-button>
                <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
                <el-button type="text" size="small"
                  @click="$router.push(`/lessonReplay/${scope.row.id}?breadNumber=5`)">直播回放
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination v-show="pages.total" :total="pages.total" :page.sync="pages.currentPage"
            :limit.sync="pages.pageSize" @pagination="handlePageChange">
          </pagination>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations
  } from "vuex";
  export default {
    data() {
      return {
        salonLessonId: undefined,
        role: "", //身份
        tableData: [], //表格数据
        course: { //课节所在课程状态
          type: 0,
          pattern: 0
        },
        formInline: {},
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
      };
    },
    computed: {},
    components: {},
    methods: {
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        this.getLessonList();
      },
      isdelete(id) {
        this.salonLessonId = id;
        this.$confirm("确定删除？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.delCourse();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      },
      // 删除课节
      async delCourse() {
        let resData = await this.$Api.Course.salonLessonDel(this.salonLessonId);
        if (resData.code == 200 && resData.msg === '请求成功') {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getLessonList(1);
        } else {
          this.$message.error(resData.msg);
        }
      },
      myfn(value) {
        this.role = value;
      },
      //获取课节列表
      async getLessonList() {
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          seminarId: this.$route.params.id
        };
        let resData = await this.$Api.Course.getSalonLessonsList(params);
        // console.log(resData);
        this.tableData = resData.data.records;
        this.pages.total = resData.data.total;
      },
      goPage(row, type) {
        if (type === 'edit') {
          this.$router.push({
            path: `/salon/lessons/edit/${row.id}`,
            query: {
              salonId: this.$route.params.id,
              breadNumber: 5
            }
          })
        } else {
          this.$router.push({
            path: `/salon/lessons/detail/${row.id}`,
            query: {
              breadNumber: 5
            }
          })
        }
      },
      // 通过id获取课程信息
      async getfindCourse() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.findCourse(id);
        // console.log(resData)
        this.course.type = resData.data.classType
        this.course.pattern = resData.data.lessonsPattern
        this.$route.meta.title = `课节管理-${resData.data.name}`;
        this.$store.commit('breadPageNameChange', `课节管理--${resData.data.name}`)
        // console.log(this.course);
      },
      // 通过班型跳转不同创建课程页面
      goCreate() {
        //获取班型 
        this.$router.push({
          path: `/salon/lessons/create/${this.$route.params.id}`,
          query: {
            breadNumber: 5,
          }
        })
      },
    },
    mounted() {
      window.scroll(0, 0)
      this.getLessonList();
      // this.getfindCourse();
    },
    created() {}
  };
</script>

<style lang="less" scoped>
  @import "../../less/table.less";
  @import "../../manage_project/less/lesson_commen.less";

  .lesson_manage_home {
    // min-height: 100vh;

    .table-wrapper {
      border-radius: 8px;
      padding-top: 10px;
      background: #fff;
      box-shadow: 0px 2px 10px 0px #f5f8fa;
    }

    .table {
      margin-top: 20px;
    }
  }
</style>